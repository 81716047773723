import React, { useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import T from 'i18n-react';
import { Link } from 'react-router-dom';

import { AppRoute } from '../../../routes/routes';
import Drawer from '../../../components/Drawer';

import {
  TITLE,
  TEXT,
  ARROW_ICON_COLOR,
  TEXT_TWO,
  WHITE
} from '../../../styles/colors';
import { ArrowSvg } from '../../../assets/react-svg/ArrowSvg';
import { AxurLogo } from '../../../assets/react-svg/AxurLogo';

export const ContentWrapper: React.FC = () => {
  const [isShowDrawer, setIsShowDrawer] = useState(false);

  const handleToggleDrawer = () => {
    const htmlElement = document.querySelector('html') as HTMLElement;
    htmlElement.style.overflow = isShowDrawer ? 'auto' : 'hidden';

    setIsShowDrawer(preState => !preState);
  };

  return (
    <>
      <Container>
        <Wrapper>
          <Header>
            <AxurLogo />

            <ul className="menu-desktop">
              <li>
                <Link to={AppRoute.About}>
                  {T.translate('home.header.about')}
                </Link>
              </li>
              <li>
                <a
                  href="https://axur.com/pt/politica-de-privacidade/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {T.translate('home.header.policies')}
                </a>
              </li>
            </ul>

            <HamburguerContainer
              type="button"
              onClick={handleToggleDrawer}
              isShowDrawer={isShowDrawer}
            >
              <span className="hamburguer" aria-label="Toggle drawer" />
            </HamburguerContainer>
          </Header>

          <Title>{T.translate('home.title')}</Title>
          <Text>{T.translate('home.text')}</Text>
          <ArrowSvg color={ARROW_ICON_COLOR} />
        </Wrapper>
      </Container>

      <Drawer show={isShowDrawer} toggle={handleToggleDrawer} />
    </>
  );
};

// EFEITO TRANSIÇÃO
const fade = keyframes`
    from {
      opacity: 0;
      
      -webkit-transform: translateX(4px);
      transform: translateX(4px);
    }
    to {
      opacity: 1;
      -webkit-transform: translateX(-4);
      transform: translateX(-4);
    }
`;

const Container = styled.section`
  background-color: ${WHITE};
  color: ${WHITE};
  font-size: 35px;
  height: 55vh;
  min-height: 300px;
  padding: 6rem 0 3.2rem 0;

  @media (max-width: 700px) {
    padding-top: 4rem;
  }

  @media (max-height: 500px) {
    height: 95%;
  }

  @media (max-width: 470px) {
    height: 95%;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-end;
  margin: 0 auto;
  max-width: 1080px;
  position: relative;
  width: 100%;
  padding: 0 2.4rem;
  animation: ${fade} 0.25s linear;
`;

const Header = styled.header`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: auto;

  a,
  a:hover,
  a:focus {
    font-size: 1.3rem;
    text-decoration: none;
    color: ${TEXT_TWO};
  }

  .menu-desktop {
    display: flex;
    flex-direction: row;
    align-items: center;

    li {
      display: flex;
      justify-content: center;
      align-items: center;

      + li {
        margin-left: 4.8rem;
      }
    }
  }

  @media (max-width: 700px) {
    .menu-desktop {
      display: none;
    }
  }
`;

const Title = styled.h1`
  font-size: 3.6rem;
  color: ${TITLE};
  margin-top: 8.5rem;
  margin-bottom: 2.4rem;
  font-weight: bold;
`;

const Text = styled.p`
  width: 100%;
  max-width: 61.6rem;
  font-size: 1.6rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  margin-bottom: 2.4rem;
  color: ${TEXT};
`;

export const HamburguerContainer = styled.button<any>`
  width: auto;
  height: 30px;
  border: none;
  outline: none;
  background: transparent;
  display: none;
  padding: 0;

  @media (max-width: 700px) {
    display: unset;
  }

  .hamburguer {
    position: relative;
    display: block;
    background: ${TEXT};
    width: 24px;
    height: 2px;
    transition: 0.5s ease-in-out;

    &::before,
    &::after {
      background: ${TEXT};
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      transition: 0.5s ease-in-out;
    }

    &::before {
      top: -8px;
    }

    &::after {
      bottom: -8px;
    }

    ${({ isShowDrawer }) =>
      isShowDrawer &&
      css`
        transform: rotate(45deg);

        &::before {
          transform: rotate(90deg);
          top: 0;
        }

        &::after {
          transform: rotate(90deg);
          bottom: 0;
        }
      `}
  }
`;
