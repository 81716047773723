import React from 'react';

interface SearchSvgProps {
  onClickHandler?: () => void;
}

export const SearchSvg: React.FC<SearchSvgProps> = ({ onClickHandler }) => {
  return (
    <svg
      data-testid="search-svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      onClick={onClickHandler}
    >
      <defs>
        <filter id="f6yu6f203a">
          <feColorMatrix
            in="SourceGraphic"
            values="0 0 0 0 0.941176 0 0 0 0 0.925490 0 0 0 0 0.886275 0 0 0 1.000000 0"
          />
        </filter>
      </defs>
      <g fill="none" fillRule="evenodd">
        <g>
          <g
            filter="url(#f6yu6f203a)"
            transform="translate(-1199 -439) translate(0 407)"
          >
            <g>
              <path d="M0 0H24V24H0z" transform="translate(1199 32)" />
              <path
                stroke="#FFF"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M11 19c4.418 0 8-3.582 8-8s-3.582-8-8-8-8 3.582-8 8 3.582 8 8 8zm10 2l-4.35-4.35"
                transform="translate(1199 32)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
