import React from 'react';
import styled from 'styled-components';

interface HeaderProps {
  bgColor: string;
  textColor: string;
}

export const Header: React.FC<HeaderProps> = ({
  bgColor,
  textColor,
  children
}) => {
  return (
    <HeaderWrapper bgColor={bgColor} textColor={textColor}>
      <Container>{children}</Container>
    </HeaderWrapper>
  );
};

interface HeaderWrapperProps {
  bgColor: string;
  textColor: string;
}

const HeaderWrapper = styled.header<HeaderWrapperProps>`
  padding: 48px 2.4rem 3.2rem;
  background-color: ${({ bgColor }) => bgColor};
  color: ${({ textColor }) => textColor};

  a,
  p {
    color: ${({ textColor }) => textColor};
    font-size: 1.6rem;
    word-wrap: break-word;
  }

  @media (min-width: 600px) {
    padding: 4.8rem 2.4rem 4.8rem;
  }
`;

const Container = styled.div`
  margin: 0 auto;
  width: 100%;

  @media (min-width: 600px) {
    max-width: 1080px;
  }
`;
