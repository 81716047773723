import React, { useMemo } from 'react';
import T from 'i18n-react';
import styled from 'styled-components';

import { AppRoute } from '../../../routes/routes';
import { BackLink } from '../../../components/BackLink';
import { Header } from '../../../components/Header';
import { Content } from '../../../components/Content';
import { ArrowSvg } from '../../../assets/react-svg/ArrowSvg';
import { Share } from '../../../components/Share';

import { SUCCESS, TEXT, TITLE, WHITE } from '../../../styles/colors';
import { KnowMore } from '../../../components/KnowMore';

interface ResultsSafeProps {
  cnpj: string;
}

export const ResultsSafe: React.FC<ResultsSafeProps> = ({ cnpj }) => {
  const parsedCnpj = useMemo(() => {
    const prefix = cnpj.substr(0, 2);
    const suffix = cnpj.substr(-2);
    const parsed = `${prefix}.xxx-xxx/xxxx-${suffix}`;

    return parsed;
  }, [cnpj]);

  return (
    <>
      <Header bgColor={SUCCESS} textColor={WHITE}>
        <BackLink to={AppRoute.Home}>
          <ArrowSvg svgName="back" color={WHITE} />
        </BackLink>
        <p>CNPJ {parsedCnpj}</p>
      </Header>

      <Content>
        <Title>Ufa! Seus dados não foram expostos. </Title>
        <P>
          Ajude colegas de outras empresas a descobrirem se também estão
          expostos.
        </P>

        <Share
          bgColor={SUCCESS}
          textColor={WHITE}
          svgColor={WHITE}
          title={T.translate('results.share.text') as string}
        />

        <InfoContainer>
          <Info>
            <h2 className="info__title">O que foi esse vazamento?</h2>

            <p className="info__text">
              Dois arquivos com dados de 40 milhões de CNPJs distintos foram
              divulgados em um fórum na internet em janeiro de 2021.
            </p>
            <p className="info__text">
              Aparentemente, eles foram compilados em agosto de 2019 e ainda não
              se tem certeza sobre a fonte do vazamento.
            </p>
            <p className="info__text mt-24">
              Matérias do{' '}
              <a
                href="https://tecnoblog.net/404863/exclusivo-o-que-ha-no-vazamento-que-afetou-40-milhoes-de-cnpjs/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Tecnoblog
              </a>
              ,{' '}
              <a
                href="https://g1.globo.com/jornal-nacional/noticia/2021/01/28/especialistas-alertam-para-os-riscos-do-vazamento-de-dados-de-223-milhoes-de-cpfs.ghtml"
                target="_blank"
                rel="noopener noreferrer"
              >
                Globo
              </a>{' '}
              e{' '}
              <a
                href="https://www1.folha.uol.com.br/tec/2021/01/vazamento-pode-ter-exposto-na-internet-220-milhoes-de-dados-pessoais-de-brasileiros.shtml"
                target="_blank"
                rel="noopener noreferrer"
              >
                UOL
              </a>{' '}
              sobre o vazamento
            </p>

            <KnowMoreDesktopComponent themeColor={SUCCESS} />
          </Info>
          <Info>
            <h2 className="info__title">Como mantenho meus dados seguros?</h2>

            <ul className="info__list">
              <li className="info__item">
                <span>
                  Evite compartilhar dados pessoais por e-mail: nome, CPF e
                  conta bancária;
                </span>
              </li>
              <li className="info__item">
                <span>Tenha cuidado com e-mails de origem desconhecida;</span>
              </li>
              <li className="info__item">
                <span>
                  Desconfie de ligações que solicitem a confirmação de dados
                  pessoais.
                </span>
              </li>
              <li className="info__item">
                <span>
                  Para mais dúvidas e informações,{' '}
                  <a
                    href="https://axur.com/pt/contato/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    fale com um de nossos especialistas.{' '}
                  </a>
                </span>
              </li>
            </ul>
          </Info>

          <KnowMoreMobileComponent themeColor={SUCCESS} />
        </InfoContainer>
      </Content>
    </>
  );
};

const P = styled.p`
  color: ${TEXT};
  font-size: 1.6rem;
  line-height: 2.4rem;
  margin-bottom: 4.8rem;
`;

const Title = styled.h1`
  font-size: 3.6rem;
  font-weight: bold;
  color: ${SUCCESS};
  margin: 0 0 2.4rem;
`;

const InfoContainer = styled.section`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 6rem 0 0;

  @media (max-width: 1100px) {
    flex-direction: column;
  }
`;

const Info = styled.div`
  flex: 1;

  .info__title,
  .info__text,
  .info__item {
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: ${TITLE};
  }

  .info__title {
    font-weight: bold;
    font-size: 2.4rem;
    line-height: normal;
    letter-spacing: normal;
  }

  .info__list {
    list-style: none;
    padding-left: 1.6rem;
  }

  .info__text,
  .info__item {
    width: 43.1rem;
    font-size: 1.6rem;
    line-height: 1.5;
    margin-top: 1.6rem;

    a {
      color: ${SUCCESS};
      text-decoration: none;
    }
  }

  .mt-24 {
    margin-top: 2.4rem;
  }

  .info__item {
    &:before {
      content: '\u2022';
      color: ${TEXT};
      font-weight: bold;
      display: inline-block;
      width: 1em;
      margin-left: -1em;
    }
  }

  @media (max-width: 1100px) {
    .info__text,
    .info__item {
      width: 100%;
    }

    .info__link {
      margin-bottom: 4.8rem;
    }

    .mt-24 {
      margin-bottom: 2.8rem;
    }
  }
`;

const KnowMoreDesktopComponent = styled(KnowMore)`
  margin-top: 5.6rem;

  @media (max-width: 1100px) {
    display: none;
  }
`;

const KnowMoreMobileComponent = styled(KnowMore)`
  margin-top: 2.8rem;
  display: none;

  @media (max-width: 1100px) {
    display: unset;
  }
`;
