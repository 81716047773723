import React from 'react';

import { TEXT_TWO } from '../../styles/colors';

interface FacebookSvgProps {
  color?: string;
}

export const FacebookSvg: React.FC<FacebookSvgProps> = ({
  color = TEXT_TWO
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="19"
      viewBox="0 0 19 19"
    >
      <g fill="none" fill-rule="evenodd">
        <g fill={color}>
          <g>
            <path
              d="M19 17.95c0 .58-.47 1.05-1.049 1.05H13.11v-7.358h2.47l.37-2.867h-2.84V6.944c0-.83.23-1.396 1.42-1.396h1.52V2.983c-.264-.035-1.165-.113-2.213-.113-2.19 0-3.688 1.336-3.688 3.79v2.115H7.673v2.867h2.476V19h-9.1C.469 19 0 18.53 0 17.95V1.05C0 .47.47 0 1.049 0H17.95C18.531 0 19 .47 19 1.05v16.9"
              transform="translate(-143 -1147) translate(143 1147)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
