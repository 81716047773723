import React from 'react';
import T from 'i18n-react';
import styled from 'styled-components';

import { Layout } from '../../components/Layout';
import { AppRoute } from '../../routes/routes';
import { BackLink } from '../../components/BackLink';
import { ArrowSvg } from '../../assets/react-svg/ArrowSvg';

import { BACKGROUND, TEXT, TEXT_TWO } from '../../styles/colors';

export const NotFoundView: React.FC = () => (
  <Layout>
    <Content>
      <Container>
        <BackLink to={AppRoute.Home}>
          <ArrowSvg svgName="back" color={TEXT} />
        </BackLink>

        <Title>Ops!</Title>
        <P>{T.translate('NotFound.text')}</P>
      </Container>
    </Content>
  </Layout>
);

const Content = styled.section`
  padding: 5.6rem 3.2rem 9.7rem;
  background-color: ${BACKGROUND};
  flex: 1;

  @media (min-width: 600px) {
    padding: 4.8rem 4.8rem 64px;
  }
`;

const Container = styled.section`
  margin: 0 auto;
  width: 100%;
  flex: 1;

  a {
    color: ${TEXT};
    margin-bottom: 4.8rem;
  }

  @media (min-width: 600px) {
    max-width: 1080px;

    a {
      margin-bottom: 88px;
    }
  }
`;

const Title = styled.h1`
  font-size: 3.6rem;
  font-weight: normal;
  color: ${TEXT_TWO};
  margin: 0 0 2.4rem;
`;

const P = styled.p`
  color: ${TEXT};
  font-size: 1.6rem;
  line-height: 2.4rem;
  margin-bottom: 2.4rem;
  max-width: 431px;
`;
