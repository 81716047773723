import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';

import styled from 'styled-components';

interface BackLinkProps {
  to: string;
  children: ReactNode;
  onClick?: (event: React.FormEvent) => void;
}

export const BackLink: React.FC<BackLinkProps> = ({
  to,
  children,
  onClick
}) => {
  return (
    <LinkWrapper to={to} onClick={onClick}>
      {children}
    </LinkWrapper>
  );
};

const LinkWrapper = styled(Link)`
  display: inline-block;
  font-size: 1.6rem;
  text-decoration: none;
  margin-bottom: 1.6rem;

  @media (min-width: 600px) {
    margin-bottom: 2.4rem;
  }
`;
