import React from 'react';
import T from 'i18n-react';
import styled, { keyframes } from 'styled-components';

import { AppRoute } from '../../routes/routes';
import { Layout } from '../../components/Layout';
import { BackLink } from '../../components/BackLink';
import { ArrowSvg } from '../../assets/react-svg/ArrowSvg';
import { Share } from '../../components/Share';
import { KnowMore } from '../../components/KnowMore';

import {
  BACKGROUND_TWO,
  TEXT,
  TEXT_TWO,
  TITLE,
  WHITE
} from '../../styles/colors';

export const AboutView: React.FC = () => (
  <Layout>
    <Content>
      <Container>
        <BackLink to={AppRoute.Home}>
          <ArrowSvg svgName="back" color={TEXT} />
        </BackLink>

        <Row>
          <div>
            <H1>{T.translate('about.title')}</H1>
            <P>
              {T.translate('about.text_1a')}{' '}
              <ExternalLink
                href="https://axur.com/pt"
                target="_blank"
                rel="noopener noreferrer"
              >
                Axur
              </ExternalLink>
              {T.translate('about.text_1b')}
            </P>
            <P
              dangerouslySetInnerHTML={{
                __html: T.translate('about.text_2') as string
              }}
            />
            <P>{T.translate('about.text_3')}</P>

            <br />
            <br />
            <Share
              bgColor={BACKGROUND_TWO}
              textColor={WHITE}
              svgColor={WHITE}
            />
          </div>
        </Row>

        <KnowMore />
      </Container>
    </Content>
  </Layout>
);

// EFEITO TRANSIÇÃO
const fade = keyframes`
    from {
      opacity: 0;
      
      -webkit-transform: translateX(4px);
      transform: translateX(4px);
    }
    to {
      opacity: 1;
      -webkit-transform: translateX(-4);
      transform: translateX(-4);
    }
`;

const Content = styled.section`
  padding: 5.6rem 3.2rem 9.7rem;
  background-color: ${WHITE};
  flex: 1;

  @media (min-width: 600px) {
    padding: 4.8rem 4.8rem 64px;
  }
`;

const Container = styled.section`
  margin: 0 auto;
  width: 100%;
  flex: 1;

  div {
    animation: ${fade} 0.25s linear;
  }

  a {
    margin-bottom: 4.8rem;
  }

  @media (min-width: 600px) {
    max-width: 1080px;

    a {
      margin-bottom: 88px;
    }
  }
`;

const Row = styled.div`
  div + div {
    margin-top: 60px;
  }

  img {
    margin-bottom: 32px;
  }

  img:nth-child(1) {
    max-width: 80px;
    margin-right: 80px;
  }
  img:nth-child(2) {
    max-width: 100px;
  }

  @media (min-width: 769px) {
    display: flex;

    div + div {
      margin-top: 0;
      margin-left: 124px;
    }
  }
`;

const H1 = styled.h1`
  font-size: 3.6rem;
  font-weight: bold;
  color: ${TITLE};
  margin: 0 0 2.4rem;
`;

const P = styled.p`
  color: ${TEXT};
  font-size: 1.6rem;
  line-height: 2.4rem;
  margin-bottom: 2.4rem;
  max-width: 431px;

  .text-purple {
    color: ${TEXT_TWO};
  }
`;

const ExternalLink = styled.a`
  color: ${TEXT_TWO};
  font-size: 1.6rem;
  text-decoration: none;
  transition: opacity 0.25s;
  font-weight: bold;

  :hover {
    opacity: 0.9;
  }
`;
