/* eslint-disable consistent-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { useRef, useEffect, useCallback } from 'react';

type Hook = (
  eventName: string,
  handler: (event?: any) => any,
  element?: any,
) => void;

export const useEventListener: Hook = (eventName, handler, element) => {
  const savedHandler = useRef<any>();

  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  const getElementWindowIfElementIsUndefined = useCallback((el: any): any => {
    if (!el && typeof window !== 'undefined') {
      return window;
    }

    return el;
  }, []);

  useEffect(() => {
    element = getElementWindowIfElementIsUndefined(element);

    const isSupported = element && element.addEventListener;

    if (!isSupported) return;

    const eventListener = (event: any): any => savedHandler.current(event);

    element.addEventListener(eventName, eventListener);

    return () => {
      element.removeEventListener(eventName, eventListener);
    };
  }, [eventName, element]);
};
