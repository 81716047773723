import React, { useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { Link, useHistory } from 'react-router-dom';

import { AppRoute } from '../../routes/routes';
import { Layout } from '../../components/Layout';
import { BackLink } from '../../components/BackLink';
import { ArrowSvg } from '../../assets/react-svg/ArrowSvg';
import { useDocument } from '../../hook/useDocument';

import { WHITE, TEXT_TWO, TEXT } from '../../styles/colors';

export const AcceptTerms: React.FC = () => {
  const history = useHistory();
  const { userDocument } = useDocument();

  useEffect(() => {
    if (!userDocument) {
      history.push(AppRoute.Home);
    }
  }, [userDocument, history]);

  if (!userDocument) {
    return null;
  }

  return (
    <Layout>
      <Content>
        <Container>
          <BackLink to={AppRoute.Home}>
            <ArrowSvg svgName="back" color={TEXT} />
          </BackLink>

          <Row>
            <div>
              <H1>Política de privacidade</H1>
              <P>
                Para prosseguir, você precisa aceitar a{' '}
                <ExternalLink
                  href="https://axur.com/pt/politica-de-privacidade/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  política de privacidade
                </ExternalLink>
                . Seu CNPJ não será armazenado em nossa base de dados.
              </P>

              <ButtonContainer>
                <AcceptButton to={AppRoute.Results}>
                  Concordo, prosseguir
                </AcceptButton>

                <BackToHome to={AppRoute.Home}>Não concordo</BackToHome>
              </ButtonContainer>
            </div>
          </Row>
        </Container>
      </Content>
    </Layout>
  );
};

// EFEITO TRANSIÇÃO
const fade = keyframes`
    from {
      opacity: 0;
      
      -webkit-transform: translateX(4px);
      transform: translateX(4px);
    }
    to {
      opacity: 1;
      -webkit-transform: translateX(-4);
      transform: translateX(-4);
    }
`;

const Content = styled.section`
  padding: 5.6rem 3.2rem 9.7rem;
  background-color: ${WHITE};
  flex: 1;

  @media (min-width: 600px) {
    padding: 4.8rem 4.8rem 64px;
  }
`;

const Container = styled.section`
  margin: 0 auto;
  width: 100%;
  flex: 1;

  div {
    animation: ${fade} 0.25s linear;
  }

  a {
    /* color: ${TEXT}; */
    margin-bottom: 4.8rem;
  }

  @media (min-width: 600px) {
    max-width: 1080px;

    a {
      margin-bottom: 88px;
    }
  }
`;

const Row = styled.div`
  div + div {
    margin-top: 60px;
  }

  img {
    margin-bottom: 32px;
  }

  img:nth-child(1) {
    max-width: 80px;
    margin-right: 80px;
  }
  img:nth-child(2) {
    max-width: 100px;
  }

  @media (min-width: 769px) {
    display: flex;

    div + div {
      margin-top: 0;
      margin-left: 124px;
    }
  }
`;

const H1 = styled.h1`
  font-size: 3.6rem;
  font-weight: bold;
  color: ${TEXT};
  margin: 0 0 2.4rem;

  @media (max-width: 520px) {
    text-align: center;
  }
`;

const P = styled.p`
  color: ${TEXT};
  font-size: 1.6rem;
  line-height: 2.4rem;
  margin-bottom: 2.4rem;
  max-width: 431px;

  @media (max-width: 520px) {
    text-align: center;
  }
`;

const ExternalLink = styled.a`
  color: ${TEXT_TWO};
  font-size: 1.6rem;
  text-decoration: none;
  transition: opacity 0.25s;
  font-weight: bold;

  :hover {
    opacity: 0.9;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 4.8rem;

  @media (max-width: 520px) {
    flex-direction: column;
  }
`;

const AcceptButton = styled(Link)`
  cursor: pointer;
  appearance: none;
  border: none;
  border-radius: 30px;
  padding: 15px 24px;
  font-size: 1.6rem;
  background-color: ${TEXT_TWO};
  color: ${WHITE};
  transition: opacity 0.25s;
  position: relative;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  margin-right: 8px;
  margin-bottom: 0 !important;

  :hover {
    opacity: 0.9;
  }
`;

const BackToHome = styled(Link)`
  font-size: 1.6rem;
  color: ${TEXT_TWO};
  text-decoration: none;
  margin-left: 3.2rem;
  margin-bottom: 0 !important;

  :hover {
    opacity: 0.9;
  }

  @media (max-width: 520px) {
    margin-left: 0;
    margin-top: 3.2rem !important;
  }
`;
