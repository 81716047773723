export const WHITE = '#ffffff';

export const BACKGROUND = '#fafafa';
export const BACKGROUND_TWO = '#871ae5';
export const SUCCESS = '#06c678';
export const WARNING = '#f9d43a';
export const DANGER = '#d66340';
export const DANGER_TWO = '#eb6d46';
export const ARROW_ICON_COLOR = '#871ae5';
export const ORANGE_RED = '#E2410F';

export const TITLE = '#010101';
export const TEXT = '#1e1e1e';
export const INPUT_COLOR = '#f0ece2';
export const TEXT_TWO = '#871ae5';
export const TEXT_THREE = '#666666';
export const TEXT_BLACK = '#010101';
