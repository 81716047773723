import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import T from 'i18n-react';

import { AppRoute } from '../../routes/routes';
import { getIdCnpjAnalysis, getCnpjAnalysis } from '../../services/cnpjChecker';
import { CnpjChecker } from '../../model/cnpjChecker';

import { Layout } from '../../components/Layout';
import { ResultsSafe } from './ResultsSafe';
import { ResultsUnsafe } from './ResultsUnsafe';
import { ResultsLoading } from './ResultsLoading';
import { ErrorView } from '../ErrorView';
import { useDocument } from '../../hook/useDocument';

export interface IResult {
  companyName: string;
  tradeName: string;
  foundationDate: string;
}

export const ResultsView: React.FC = () => {
  const history = useHistory();
  const { userDocument } = useDocument();
  const [cnpjToVerify, setCnpjToVerify] = useState('');
  const [cnpjAnalysis, setCnpjAnalysis] = useState<{
    status: CnpjChecker;
    result?: IResult;
  }>({
    status: CnpjChecker.LOADING
  });

  useEffect(() => {
    if (userDocument) {
      setCnpjToVerify(userDocument);
    } else {
      history.push(AppRoute.Home);
    }
  }, [userDocument, history]);

  useEffect(() => {
    (async () => {
      if (!!cnpjToVerify) {
        const idUrlAnalysis = await getIdCnpjAnalysis(cnpjToVerify);

        const idIsValid =
          idUrlAnalysis !== CnpjChecker.ERROR_PENDING_ANALYSIS &&
          idUrlAnalysis !== CnpjChecker.NOT_FOUND &&
          idUrlAnalysis !== CnpjChecker.ERROR_UNKNOWN;

        if (idIsValid) {
          let analysisWithValidation = await getCnpjAnalysis(idUrlAnalysis);
          if (!analysisWithValidation) {
            analysisWithValidation = {
              status: CnpjChecker.ERROR_PENDING_ANALYSIS
            };
          }

          setCnpjAnalysis(analysisWithValidation);
        } else {
          setCnpjAnalysis(idUrlAnalysis);
        }
      }
    })();
  }, [cnpjToVerify]);

  if (!userDocument) {
    return null;
  }

  return (
    <Layout>
      {
        {
          [CnpjChecker.LOADING]: <ResultsLoading cnpj={cnpjToVerify} />,
          [CnpjChecker.FOUND]: (
            <ResultsUnsafe cnpj={cnpjToVerify} result={cnpjAnalysis.result} />
          ),

          [CnpjChecker.ERROR_PENDING_ANALYSIS]: (
            <ErrorView text={T.translate('error.pendingAnalysis') as string} />
          ),
          [CnpjChecker.NOT_FOUND]: <ResultsSafe cnpj={cnpjToVerify} />,
          [CnpjChecker.ERROR_UNKNOWN]: (
            <ErrorView text={T.translate('error.unknownSearch') as string} />
          )
        }[cnpjAnalysis.status]
      }
    </Layout>
  );
};
