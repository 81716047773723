import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import { render } from 'react-dom';

// Extra imports
import 'normalize.css/normalize.css';

// App imports
import configurei18n from './i18n/configure';

import { AppRoutes } from './routes/AppRoutes';

(async () => {
  await configurei18n();

  const renderApp = () => {
    render(<AppRoutes />, document.getElementById('app'));
  };

  renderApp();
})();
