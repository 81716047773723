import React, { useCallback } from 'react';
import T from 'i18n-react';
import { Link } from 'react-router-dom';

import { useEventListener } from '../../hook/useEventListener';
import { AppRoute } from '../../routes/routes';

import { Container } from './styles';

interface Props {
  show: boolean;
  toggle(): void;
}

const Drawer: React.FC<Props> = ({ show, toggle }) => {
  const activeScroll = () => {
    const htmlElement = document.querySelector('html') as HTMLElement;
    htmlElement.style.overflow = 'auto';
  };

  const addScroll = useCallback(() => {
    if (window.innerWidth > 960 && show) {
      activeScroll();
      toggle();
    }
  }, [show, toggle]);

  useEventListener('resize', addScroll);

  return (
    <Container show={show} data-testid="drawer" aria-hidden={show}>
      <ul>
        <li>
          <Link to={AppRoute.About} onClick={activeScroll}>
            {T.translate('home.header.about')}
          </Link>
        </li>
        <li>
          <a
            href="https://axur.com/pt/politica-de-privacidade/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {T.translate('home.header.policies')}
          </a>
        </li>
      </ul>
    </Container>
  );
};

export default Drawer;
