import React from 'react';
import styled from 'styled-components';

import { TEXT, TEXT_TWO } from '../../styles/colors';
import { FacebookSvg } from '../../assets/react-svg/FacebookSvg';
import { LinkedinSvg } from '../../assets/react-svg/LinkedinSvg';
import { SpotifySvg } from '../../assets/react-svg/SpotifySvg';
import { InstagramSvg } from '../../assets/react-svg/InstgramSvg';
import { YoutubeSvg } from '../../assets/react-svg/YoutubeSvg';
import { TwitterSvg } from '../../assets/react-svg/TwitterSvg';

interface SocialLinksProps {
  themeColor?: string;
}

export const SocialLinks = ({ themeColor = TEXT_TWO }: SocialLinksProps) => {
  return (
    <Container>
      <p>Conheça a Axur nas plataformas:</p>

      <div className="links-container">
        <a
          href="https://www.facebook.com/AxurBrasil/"
          target="_blank"
          rel="noopener noreferrer"
          title="Facebook"
          aria-label="Facebook"
        >
          <FacebookSvg color={themeColor} />
        </a>
        <a
          href="https://www.linkedin.com/company/axur"
          target="_blank"
          rel="noopener noreferrer"
          title="LinkedIn"
          aria-label="LinkedIn"
        >
          <LinkedinSvg color={themeColor} />
        </a>
        <a
          href="https://open.spotify.com/show/6lCnGKy1D8HvKutsx3Xt5F"
          target="_blank"
          rel="noopener noreferrer"
          title="Spotify"
          aria-label="Spotify"
        >
          <SpotifySvg color={themeColor} />
        </a>
        <a
          href="https://www.instagram.com/axurbr/"
          target="_blank"
          rel="noopener noreferrer"
          title="Instagram"
          aria-label="Instagram"
        >
          <InstagramSvg color={themeColor} />
        </a>
        <a
          href="https://www.youtube.com/c/AxurBrasil"
          target="_blank"
          rel="noopener noreferrer"
          title="Youtube"
          aria-label="Youtube"
        >
          <YoutubeSvg color={themeColor} />
        </a>
        <a
          href="https://twitter.com/AxurBrasil/"
          target="_blank"
          rel="noopener noreferrer"
          title="Twitter"
          aria-label="Twitter"
        >
          <TwitterSvg color={themeColor} />
        </a>
      </div>
    </Container>
  );
};

const Container = styled.div`
  margin-top: 4.8rem;

  p {
    font-size: 1.6rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: ${TEXT};
  }

  .links-container {
    margin-top: 3.2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;

    a {
      text-decoration: none;
      margin-bottom: 2rem;
      margin-right: 3.2rem;
    }
  }
`;
