const cnpjValidator = (cnpj: string): boolean => {
  const parsedCnpj = cnpj.replace(/\.|-|\//g, '');

  if (parsedCnpj.length < 14) {
    return false;
  }

  let equalDigits = 1;
  for (let i = 0; i < parsedCnpj.length - 1; i++) {
    if (parsedCnpj.charAt(i) !== parsedCnpj.charAt(i + 1)) {
      equalDigits = 0;
      break;
    }
  }

  if (equalDigits) {
    return false;
  }

  let size = parsedCnpj.length - 2;
  let numbers = parsedCnpj.substring(0, size);
  let digits = parsedCnpj.substring(size);
  let sum = 0;
  let pos = size - 7;

  for (let i = size; i >= 1; i--) {
    sum += Number(numbers.charAt(size - i)) * pos--;
    if (pos < 2) {
      pos = 9;
    }
  }

  let result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
  if (String(result) !== digits.charAt(0)) {
    return false;
  }

  size = size + 1;
  numbers = parsedCnpj.substring(0, size);
  sum = 0;
  pos = size - 7;
  for (let i = size; i >= 1; i--) {
    sum += Number(numbers.charAt(size - i)) * pos--;
    if (pos < 2) {
      pos = 9;
    }
  }

  result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
  const expRegQtdChar = '^\\d{14}$';
  if (String(result) !== digits.charAt(1) || !parsedCnpj.match(expRegQtdChar)) {
    return false;
  }

  return true;
};

export default cnpjValidator;
