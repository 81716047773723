import React from 'react';

import { TEXT_TWO } from '../../styles/colors';

interface InstagramSvgProps {
  color?: string;
}

export const InstagramSvg: React.FC<InstagramSvgProps> = ({
  color = TEXT_TWO
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="19"
      viewBox="0 0 19 19"
    >
      <g fill="none" fill-rule="evenodd">
        <g fill={color}>
          <g>
            <path
              d="M161.46.003h1.04c3.094 0 3.461.011 4.683.067 1.13.051 1.743.24 2.151.399.541.21.927.46 1.332.866.406.406.657.792.867 1.333.159.408.348 1.021.4 2.151.055 1.222.067 1.589.067 4.681 0 3.093-.012 3.46-.068 4.682-.051 1.13-.24 1.743-.399 2.15-.21.542-.461.927-.867 1.332-.405.406-.79.657-1.332.867-.408.159-1.021.347-2.151.399-1.222.056-1.589.068-4.683.068s-3.461-.012-4.683-.068c-1.13-.052-1.743-.241-2.151-.4-.541-.21-.927-.46-1.333-.866-.406-.406-.657-.791-.867-1.332-.159-.408-.348-1.021-.4-2.151-.055-1.222-.066-1.589-.066-4.684 0-3.094.011-3.46.067-4.68.051-1.13.24-1.744.399-2.152.21-.541.461-.927.867-1.333.406-.406.792-.657 1.333-.867.408-.16 1.022-.348 2.151-.4 1.07-.048 1.484-.063 3.644-.065v.003zm5.835 3.314c0 .768.623 1.39 1.39 1.39.769 0 1.392-.622 1.392-1.39 0-.768-.623-1.39-1.391-1.39s-1.39.622-1.39 1.39zM156.55 9.503c0 3.287 2.665 5.95 5.951 5.95 3.287 0 5.95-2.663 5.95-5.95 0-3.287-2.664-5.951-5.95-5.951s-5.951 2.664-5.951 5.951zM162.5 5.64c2.133 0 3.863 1.73 3.863 3.863s-1.73 3.863-3.863 3.863-3.863-1.73-3.863-3.863 1.73-3.863 3.863-3.863z"
              transform="translate(-296 -1147) translate(143 1147)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
