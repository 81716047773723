import React from 'react';

import { TEXT_TWO } from '../../styles/colors';

interface SpotifySvgProps {
  color?: string;
}

export const SpotifySvg: React.FC<SpotifySvgProps> = ({ color = TEXT_TWO }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="19"
      viewBox="0 0 19 19"
    >
      <g fill="none" fill-rule="evenodd">
        <g fill={color}>
          <g>
            <path
              d="M111.5.031c-5.231 0-9.471 4.24-9.471 9.471 0 5.23 4.24 9.47 9.47 9.47 5.232 0 9.472-4.24 9.472-9.47s-4.24-9.47-9.472-9.47zm4.343 13.66c-.17.278-.534.366-.812.196-2.224-1.359-5.023-1.666-8.32-.913-.318.072-.634-.127-.707-.445-.072-.317.126-.634.444-.706 3.608-.825 6.703-.47 9.2 1.056.277.17.366.534.195.812zm1.16-2.58c-.215.349-.67.458-1.017.245-2.545-1.566-6.426-2.019-9.437-1.105-.39.118-.803-.102-.922-.492-.117-.39.103-.802.493-.92 3.44-1.044 7.715-.539 10.639 1.258.347.214.457.668.243 1.015zm.099-2.684c-3.053-1.814-8.089-1.98-11.003-1.096-.468.142-.963-.122-1.105-.59-.142-.468.122-.963.59-1.105 3.346-1.016 8.907-.82 12.422 1.267.422.25.56.793.31 1.214-.25.42-.794.56-1.214.31z"
              transform="translate(-245 -1147) translate(143 1147)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
