import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { AppRoute } from './routes';
import { HomeView } from '../views/HomeView';
import { ResultsView } from '../views/ResultsView';
import { AboutView } from '../views/AboutView';
import { AcceptTerms } from '../views/AcceptTerms';
import { NotFoundView } from '../views/NotFoundView';
import { DocumentProvider } from '../hook/useDocument';

export const AppRoutes = () => (
  <DocumentProvider>
    <BrowserRouter>
      <Switch>
        <Route path={AppRoute.Home} exact component={HomeView} />
        <Route path={AppRoute.Results} exact component={ResultsView} />
        <Route path={AppRoute.About} exact component={AboutView} />
        <Route path={AppRoute.AcceptTerms} exact component={AcceptTerms} />
        <Route component={NotFoundView} />
      </Switch>
    </BrowserRouter>
  </DocumentProvider>
);
