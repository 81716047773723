import React, { AnchorHTMLAttributes } from 'react';
import styled from 'styled-components';

import { TEXT_TWO } from '../../styles/colors';

interface SecundaryLinkProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  className?: string;
  children: string;
}

export const SecundaryLink = ({
  children,
  className = '',
  ...rest
}: SecundaryLinkProps) => {
  return (
    <Link className={className} {...rest}>
      {children}
    </Link>
  );
};

const Link = styled.a`
  height: 5.3rem;
  width: fit-content;

  margin-bottom: 0 !important;

  appearance: none;
  border: solid 2px ${TEXT_TWO};
  border-radius: 30px;
  padding: 0 2.4rem;
  font-size: 1.6rem;
  background-color: transparent;
  color: ${TEXT_TWO};
  text-decoration: none;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s;

  :hover {
    background-color: ${TEXT_TWO};
    color: white;
  }
`;
