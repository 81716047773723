import React from 'react';

import { TEXT_TWO } from '../../styles/colors';

interface TwitterSvgProps {
  color?: string;
}

export const TwitterSvg: React.FC<TwitterSvgProps> = ({ color = TEXT_TWO }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="19"
      viewBox="0 0 20 19"
    >
      <g fill="none" fill-rule="evenodd">
        <g fill={color}>
          <g>
            <g>
              <path
                d="M16.84 2.354C16.095 1.521 15.028 1 13.847 1c-2.264 0-4.102 1.922-4.102 4.292 0 .336.036.662.106.977-3.41-.18-6.433-1.886-8.458-4.486-.353.636-.555 1.374-.555 2.16 0 1.489.724 2.802 1.826 3.572-.672-.02-1.306-.216-1.86-.535v.052c0 2.08 1.414 3.816 3.293 4.209-.344.1-.707.15-1.082.15-.264 0-.522-.025-.772-.075.523 1.704 2.037 2.945 3.833 2.979C4.67 15.446 2.9 16.132.979 16.132c-.331 0-.659-.02-.979-.059C1.816 17.29 3.974 18 6.29 18c7.548 0 11.674-6.538 11.674-12.21 0-.187-.003-.373-.01-.556.802-.605 1.498-1.36 2.046-2.221-.735.34-1.526.572-2.356.675.847-.53 1.498-1.372 1.804-2.374-.793.492-1.67.849-2.607 1.04z"
                transform="translate(-398 -1147) translate(143 1147) translate(255)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
