import React from 'react';

import { Layout } from '../../components/Layout';
import { ActionsWrapper } from './homeView/ActionsWrapper';
import { ContentWrapper } from './homeView/ContentWrapper';

export const HomeView: React.FC = () => (
  <Layout>
      <ContentWrapper />
      <ActionsWrapper />
  </Layout>
);
