import config from '../config/dados-expostos-frontend.json';

const GATEWAY_BASE_URL = config.gatewayUrl;

export const CNPJ_CHECKER_CREATE = `${GATEWAY_BASE_URL}/leak-checker/create`;

export const CNPJ_CHECKER_READ = (id: string) =>
  `${GATEWAY_BASE_URL}/leak-checker/read/${id}`;

export const MANUAL_ANALYSIS = (id: string) =>
  `${GATEWAY_BASE_URL}/leak-checker/manual-analysis/${id}`;
