import React from 'react';
import styled from 'styled-components';
import T from 'i18n-react';

import { SocialLinks } from '../SocialLinks';
import { SecundaryLink } from '../../components/SecundaryLink';
import { TEXT, TEXT_TWO, TITLE } from '../../styles/colors';

interface KnowMoreProps {
  themeColor?: string;
  className?: string;
}

interface LinkProps {
  $themeColor: string;
}

export const KnowMore = ({
  themeColor = TEXT_TWO,
  className = ''
}: KnowMoreProps) => {
  return (
    <Container className={className}>
      <H2>{T.translate('about.aboutMoreTitle')}</H2>

      <P>{T.translate('about.aboutMoreText')}</P>

      <Link
        href="https://axur.com"
        target="_blank"
        rel="noopener noreferrer"
        $themeColor={themeColor}
      >
        Visitar site
      </Link>

      <SocialLinks themeColor={themeColor} />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;

  margin-top: 8rem;
`;

const P = styled.p`
  color: ${TEXT};
  font-size: 1.6rem;
  line-height: 2.4rem;
  margin-bottom: 2.4rem;
  max-width: 431px;

  .text-purple {
    color: ${TEXT_TWO};
  }
`;

const H2 = styled.h2`
  font-size: 2.4rem;
  font-weight: bold;
  color: ${TITLE};
`;

const Link = styled(SecundaryLink)<LinkProps>`
  margin-top: 3.2rem;
  border: solid 2px ${({ $themeColor }) => $themeColor};
  color: ${({ $themeColor }) => $themeColor};

  :hover {
    background-color: ${({ $themeColor }) => $themeColor};
    color: white;
  }
`;
