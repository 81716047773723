import React, { useLayoutEffect } from 'react';
import styled from 'styled-components';

import { WHITE } from '../../styles/colors';

import { GlobalStyle } from '../../styles/GlobalStyle';

export const Layout: React.FC = ({ children }) => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container>
      <GlobalStyle />
      {children}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  background: ${WHITE};
`;
