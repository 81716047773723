import styled from 'styled-components';

import { TEXT_TWO, WHITE } from '../../styles/colors';

interface ContainerProps {
  show: boolean;
}

export const Container = styled.div<ContainerProps>`
  height: 100%;
  width: 100%;
  height: calc(100% - 80px);
  position: fixed;
  display: flex;
  flex-direction: column;
  z-index: 1;
  top: 80px;
  right: ${props => (props.show ? 0 : '-100%')};
  background-color: ${WHITE};
  overflow-x: hidden;
  transition: 0.5s;
  z-index: 4;
  padding: 0 2.4rem;

  ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    li {
      margin-top: 2.4rem;

      a {
        &,
        &:hover,
        &:focus {
          color: ${TEXT_TWO};
          text-decoration: none;
          font-size: 1.6rem;
        }
      }
    }
  }

  @media (min-width: 701px) {
    display: none;
  }
`;

export const ContainerSharedButton = styled.div`
  margin-top: auto;
  margin-bottom: 48px;
  width: 100%;
  display: flex;
  justify-content: center;
`;
