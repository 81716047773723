import React from 'react';

import styled, { keyframes } from 'styled-components';

import { WHITE } from '../../styles/colors';

export const Content: React.FC = ({ children }) => (
  <ContentWrapper>
    <Container>{children}</Container>
  </ContentWrapper>
);

// EFEITO TRANSIÇÃO
const fade = keyframes`
  from {
    opacity: 0;
    
    -webkit-transform: translateX(4px);
    transform: translateX(4px);
  }
  to {
    opacity: 1;
    -webkit-transform: translateX(-4);
    transform: translateX(-4);
  }
`;

const ContentWrapper = styled.section`
  padding: 4.8rem 2.4rem 9.7rem;
  background-color: ${WHITE};
  flex: 1;

  @media (min-width: 600px) {
    padding: 8rem 2.4rem 64px;
  }
`;

const Container = styled.div`
  margin: 0 auto;
  width: 100%;
  height: 100%;
  animation: ${fade} 0.25s linear;

  position: relative;

  @media (min-width: 600px) {
    max-width: 1080px;
  }
`;
