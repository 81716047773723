import React from 'react';

import { TEXT_TWO } from '../../styles/colors';

interface YoutubeSvgProps {
  color?: string;
}

export const YoutubeSvg: React.FC<YoutubeSvgProps> = ({ color = TEXT_TWO }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="19"
      viewBox="0 0 19 19"
    >
      <g fill="none" fill-rule="evenodd">
        <g fill={color}>
          <g>
            <path
              d="M7.606 12.578V6.834l4.946 2.872-4.946 2.872zm11.002-7.48c-.219-.82-.86-1.467-1.674-1.688C15.447 3 9.5 3 9.5 3s-5.947 0-7.434.395C1.268 3.615.61 4.278.39 5.099 0 6.597 0 9.706 0 9.706s0 3.124.391 4.607c.22.82.861 1.468 1.675 1.689 1.502.41 7.434.41 7.434.41s5.947 0 7.434-.395c.814-.22 1.455-.868 1.675-1.688.39-1.5.39-4.607.39-4.607s.017-3.125-.39-4.623z"
              transform="translate(-347 -1147) translate(347 1147)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
