import React from 'react';

import { TEXT_TWO } from '../../styles/colors';

interface LinkedinSvgProps {
  color?: string;
}

export const LinkedinSvg: React.FC<LinkedinSvgProps> = ({
  color = TEXT_TWO
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="19"
      viewBox="0 0 19 19"
    >
      <g fill="none" fill-rule="evenodd">
        <g fill={color}>
          <g>
            <path
              d="M17.599 0H1.4C.633 0 0 .61 0 1.362v16.276C0 18.39.633 19 1.401 19H17.6c.776 0 1.401-.61 1.401-1.362V1.362C19 .61 18.367 0 17.599 0zM5.755 15.912H2.89V7.316h2.865v8.598zM4.33 6.143h-.023c-.966 0-1.584-.657-1.584-1.48 0-.84.634-1.48 1.623-1.48.982 0 1.584.633 1.6 1.48 0 .823-.618 1.48-1.623 1.48h.007zm11.772 9.77H13.23V11.32c0-1.148-.412-1.94-1.449-1.94-.792 0-1.267.53-1.48 1.045-.08.182-.088.436-.088.697v4.79H7.348s.04-7.775 0-8.582h2.873V8.55c.53-.918 1.523-1.466 2.581-1.425 1.892 0 3.31 1.235 3.31 3.871v4.916h-.009z"
              transform="translate(-194 -1147) translate(194 1147)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
