import React, { useMemo } from 'react';
import styled from 'styled-components';

import { AppRoute } from '../../../routes/routes';
import { Header } from '../../../components/Header';
import { BackLink } from '../../../components/BackLink';
import { ArrowSvg } from '../../../assets/react-svg/ArrowSvg';
import { IResult } from '../ResultsView';

import { TEXT, DANGER, DANGER_TWO, WHITE, TITLE } from '../../../styles/colors';
import { KnowMore } from '../../../components/KnowMore';

interface ResultsUnsafeProps {
  cnpj: string;
  result?: IResult;
}

export const ResultsUnsafe: React.FC<ResultsUnsafeProps> = ({
  cnpj,
  result = undefined
}) => {
  const parsedCnpj = useMemo(() => {
    const prefix = cnpj.substr(0, 2);
    const suffix = cnpj.substr(-2);
    const parsed = `${prefix}.xxx-xxx/xxxx-${suffix}`;

    return parsed;
  }, [cnpj]);

  return (
    <>
      <Header bgColor={DANGER} textColor={WHITE}>
        <BackLink to={AppRoute.Home}>
          <ArrowSvg svgName="back" color={WHITE} />
        </BackLink>
        <p>CNPJ {parsedCnpj}</p>
      </Header>

      <Content>
        <Container>
          <Title>Seus dados foram expostos:</Title>
          <p>
            Estes são os dados que obtivemos na base. Existem outros dados à
            venda, como telefone, endereço, número de funcionários, etc.
          </p>
        </Container>

        <ResultsContainer>
          <Result>
            <dt className="result__label">Razão Social:</dt>
            <dd className="result__text">{result?.companyName}</dd>
          </Result>
          <Result>
            <dt className="result__label">Data de fundação:</dt>
            <dd className="result__text">{result?.foundationDate}</dd>
          </Result>
          {result?.tradeName && (
            <Result>
              <dt className="result__label">Nome Fantasia:</dt>
              <dd className="result__text">{result?.tradeName}</dd>
            </Result>
          )}
        </ResultsContainer>
      </Content>

      <InfoContainer>
        <Row>
          <Info>
            <h2 className="info__title">O que foi esse vazamento?</h2>

            <p className="info__text">
              Dois arquivos com dados de 40 milhões de CNPJs distintos foram
              divulgados em um fórum na internet em janeiro de 2021.
            </p>
            <p className="info__text">
              Aparentemente, eles foram compilados em agosto de 2019 e ainda não
              se tem certeza sobre a fonte do vazamento.
            </p>
            <p className="info__text mt-24">
              Matérias do{' '}
              <a
                href="https://tecnoblog.net/404863/exclusivo-o-que-ha-no-vazamento-que-afetou-40-milhoes-de-cnpjs/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Tecnoblog
              </a>
              ,{' '}
              <a
                href="https://g1.globo.com/jornal-nacional/noticia/2021/01/28/especialistas-alertam-para-os-riscos-do-vazamento-de-dados-de-223-milhoes-de-cpfs.ghtml"
                target="_blank"
                rel="noopener noreferrer"
              >
                Globo
              </a>{' '}
              e{' '}
              <a
                href="https://www1.folha.uol.com.br/tec/2021/01/vazamento-pode-ter-exposto-na-internet-220-milhoes-de-dados-pessoais-de-brasileiros.shtml"
                target="_blank"
                rel="noopener noreferrer"
              >
                UOL
              </a>{' '}
              sobre o vazamento
            </p>

            <KnowMoreDesktopComponent themeColor={DANGER_TWO} />
          </Info>
          <Info>
            <h2 className="info__title">Estou nessa, e agora?</h2>

            <ul className="info__list">
              <li className="info__item">
                <span>
                  Acesse o site{' '}
                  <a
                    href="https://www.bcb.gov.br/cidadaniafinanceira/registrato"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <strong>Registrato</strong>
                  </a>
                  , do Banco Central, opção Pessoa Jurídica, e verifique se há
                  alguma conta bancária aberta sem permissão da sua empresa;
                </span>
              </li>
              <li className="info__item">
                <span>
                  Cadastre-se no{' '}
                  <a
                    href="https://hashcast.axur.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <strong>Hashcast™</strong>
                  </a>{' '}
                  e receba alertas se vazarem senhas associadas a e-mails de
                  funcionários.
                </span>
              </li>
              <li className="info__item">
                <span>
                  Acompanhe seu extrato bancário e fique atento a transações
                  desconhecidas;
                </span>
              </li>
              <li className="info__item">
                <span>
                  Cadastre as chaves Pix nos bancos em que a empresa possuir
                  conta, antes que alguém o faça;
                </span>
              </li>
              <li className="info__item">
                <span>
                  Ative a dupla autenticação (aquela que envia um código pelo
                  celular ou pelo email) para acesso a todos os sistemas da
                  empresa;
                </span>
              </li>
              <li className="info__item">
                <span>
                  Oriente os funcionários, especialmente equipes de atendimento,
                  a checar a identidade de pessoas que tentem se passar por
                  empresas fornecedoras, clientes ou parceiras.
                </span>
              </li>
              <li className="info__item">
                <span>
                  Oriente as equipes a desconfiar de ligações que solicitem a
                  confirmação de dados pessoais.
                </span>
              </li>
              <li className="info__item">
                <span>
                  Para mais dúvidas e informações,{' '}
                  <a
                    href="https://axur.com/pt/contato/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    fale com um de nossos especialistas.{' '}
                  </a>
                </span>
              </li>
            </ul>
          </Info>

          <KnowMoreMobileComponent themeColor={DANGER_TWO} />
        </Row>
      </InfoContainer>
    </>
  );
};

const Content = styled.section`
  padding: 4.8rem 3.2rem 4.8rem;
  background-color: ${DANGER_TWO};

  p {
    width: 615px;
    color: white;
    font-size: 1.6rem;
    line-height: 2.4rem;
    margin-bottom: 3.2rem;
  }

  @media (max-width: 1100px) {
    p {
      width: 100%;
    }
  }

  @media (min-width: 600px) {
    padding: 8rem 4.8rem 6.4rem;
  }
`;

const Container = styled.div`
  margin: 0 auto;
  width: 100%;

  @media (min-width: 600px) {
    max-width: 1080px;
  }
`;

const Title = styled.h1`
  font-size: 3.6rem;
  font-weight: bold;
  color: white;
  margin: 0 0 2.4rem;
`;

const InfoContainer = styled.section`
  width: 100%;
  background: ${WHITE};
  padding: 4.8rem 2.4rem 9.7rem;

  @media (min-width: 600px) {
    padding: 5.6rem 3.2rem 9.7rem;
  }
`;

const Row = styled.div`
  width: 100%;
  max-width: 108rem;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 1100px) {
    flex-direction: column;
  }
`;

const Info = styled.div`
  flex: 1;

  .info__title,
  .info__text,
  .info__item {
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: ${TITLE};
  }

  .info__title {
    font-weight: bold;
    font-size: 2.4rem;
    line-height: normal;
    letter-spacing: normal;
    margin-bottom: 0.8rem;
  }

  .info__list {
    list-style: none;
    padding-left: 1.6rem;
  }

  .info__text,
  .info__item {
    width: 43.1rem;
    font-size: 1.6rem;
    line-height: 1.5;
    margin-top: 1.6rem;

    a {
      color: ${DANGER_TWO};
      text-decoration: none;
    }
  }

  .mt-24 {
    margin-top: 2.4rem;
  }

  .info__item {
    &:before {
      content: '\u2022';
      color: ${TEXT};
      font-weight: bold;
      display: inline-block;
      width: 1em;
      margin-left: -1em;
    }
  }

  @media (max-width: 1100px) {
    .info__text,
    .info__item {
      width: 100%;
    }

    .mt-24 {
      margin-bottom: 2.8rem;
    }
  }
`;

const ResultsContainer = styled.div`
  width: 100%;
  max-width: 108rem;
  margin: 0.8rem auto 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  @media (max-width: 1100px) {
    flex-direction: column;
  }
`;

const Result = styled.dl`
  width: 50%;
  margin-top: 3.2rem;
  margin-bottom: 0;

  .result__label {
    font-weight: bold;
  }

  .result__text {
    font-weight: normal;
    margin: 0;
    margin-top: 0.8rem;
  }

  .result__label,
  .result__text {
    font-size: 1.6rem;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: white;
  }

  @media (max-width: 1100px) {
    margin-top: 2.4rem;
  }
`;

const KnowMoreDesktopComponent = styled(KnowMore)`
  margin-top: 5.6rem;

  @media (max-width: 1100px) {
    display: none;
  }
`;

const KnowMoreMobileComponent = styled(KnowMore)`
  margin-top: 2.8rem;
  display: none;

  @media (max-width: 1100px) {
    display: unset;
  }
`;
