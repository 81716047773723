import React from 'react';

interface ShareSvgProps {
  color?: string;
}

export const ShareSvg: React.FC<ShareSvgProps> = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="18"
      viewBox="0 0 17 18"
    >
      <g
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g stroke={color} strokeWidth="1.5">
          <g>
            <g transform="translate(-166 -573) translate(143 556) translate(24 18)">
              <circle cx="12" cy="2.4" r="2.4" />
              <circle cx="2.4" cy="8" r="2.4" />
              <circle cx="12" cy="13.6" r="2.4" />
              <path d="M4.472 9.208L9.936 12.392M9.928 3.608L4.472 6.792" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
