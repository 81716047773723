import axios from 'axios';
import { CnpjChecker } from '../model/cnpjChecker';
import { getAxiosInstanceWithDelay } from '../requests/axiosInstanceWithDelay';
import {
  CNPJ_CHECKER_CREATE,
  CNPJ_CHECKER_READ
} from '../routes/gatewayRoutes';

const AXIOS_RETRIES = 5;
const DEFAULT_ATTEMPT_COUNTER = 1;
const INITIAL_MILLISECONDS_COOLDOWN = 2000;
const GENERAL_MILLISECONDS_COOLDOWN = 3000;

export const getIdCnpjAnalysis = async (cnpj: string) => {
  const body = {
    type: 'CNPJ',
    key: cnpj
  };
  return axios
    .post(CNPJ_CHECKER_CREATE, body)
    .then(response => response.data.id)
    .catch(error => ({ status: CnpjChecker.ERROR_UNKNOWN }));
};

export const getCnpjAnalysis = (
  id: string,
  attemptCounter: number = DEFAULT_ATTEMPT_COUNTER
): any => {
  const isInitialAttemptCounter = attemptCounter === 1 || !attemptCounter;
  const millisecondsCooldown = isInitialAttemptCounter
    ? INITIAL_MILLISECONDS_COOLDOWN
    : GENERAL_MILLISECONDS_COOLDOWN;

  const axiosInstanceWithDelay = getAxiosInstanceWithDelay(
    millisecondsCooldown
  );

  return axiosInstanceWithDelay
    .get(CNPJ_CHECKER_READ(id))
    .then(response => {
      const shouldRetry =
        response.status === 204 && attemptCounter <= AXIOS_RETRIES;

      if (shouldRetry) {
        const newAttemptCounter = attemptCounter + 1;
        return getCnpjAnalysis(id, newAttemptCounter);
      } else {
        let analysys = response.data.result;

        return response.status === 200
          ? { status: analysys, result: response.data.leak }
          : { status: CnpjChecker.ERROR_PENDING_ANALYSIS };
      }
    })
    .catch(error => ({ status: CnpjChecker.ERROR_UNKNOWN }));
};
