import axios from 'axios';

const DEFAULT_MILLISECONDS_COOLDOWN = 3000;

const injectSchedulerInterceptor = (axiosInstance, millisecondsCooldown) => {
  const scheduler = config => {
    return new Promise(resolve => {
      setTimeout(() => resolve(config), millisecondsCooldown);
    });
  };

  axiosInstance.interceptors.request.use(scheduler);
};

export const getAxiosInstanceWithDelay = (
  millisecondsCooldown = DEFAULT_MILLISECONDS_COOLDOWN
) => {
  const axiosInstance = axios.create();
  injectSchedulerInterceptor(axiosInstance, millisecondsCooldown);

  return axiosInstance;
};
