import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import T from 'i18n-react';
import { useHistory } from 'react-router-dom';
import InputMask from 'react-input-mask';

import { SearchSvg } from '../../../assets/react-svg/SearchSvg';
import { BACKGROUND, BACKGROUND_TWO, WHITE } from '../../../styles/colors';
import { AppRoute } from '../../../routes/routes';
import cnpjValidator from '../../../validators/cnpjValidator';
import { useDocument } from '../../../hook/useDocument';

export const ActionsWrapper: React.FC = () => {
  const history = useHistory();
  const [cnpj, setCnpj] = useState('');
  const [isInvalidCnpj, setIsInvalidCnpj] = useState(false);

  const { setUserDocument } = useDocument();

  const onKeySubmitHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const ENTER_KEY = 'Enter';

    if (event.key === ENTER_KEY) {
      event.preventDefault();
      redirectToResults();
    }
  };

  const changeTextHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newText = event.target.value.replace(/\.|-|\//g, '');
    setCnpj(newText);

    const isValidCnpj = cnpjValidator(newText);

    if (isValidCnpj) {
      setIsInvalidCnpj(false);
    }
  };

  const onClickSvgHandler = () => {
    redirectToResults();
  };

  const redirectToResults = () => {
    if (!!cnpj.trim()) {
      setIsInvalidCnpj(false);

      const isValidCnpj = cnpjValidator(cnpj);

      if (isValidCnpj) {
        const parsedCnpj = cnpj.replace(/\.|-/g, '');
        setUserDocument(parsedCnpj);
        history.push(AppRoute.AcceptTerms);
      } else {
        setIsInvalidCnpj(true);
      }
    }
  };

  return (
    <Container>
      <Wrapper>
        <Input
          type="tel"
          onChange={changeTextHandler}
          onKeyPress={onKeySubmitHandler}
          placeholder={T.translate('home.placeholder') as string}
          value={cnpj}
          name="cnpj"
          mask="99.999.999/9999-99"
          autoComplete="off"
          data-testid="actions-Input"
        />
        <SearchSvg onClickHandler={onClickSvgHandler} />
      </Wrapper>
      {isInvalidCnpj && <small className="invalid-cnpj">CNPJ inválido</small>}
    </Container>
  );
};

// EFEITO TRANSIÇÃO
const fade = keyframes`
    from {
      opacity: 0;
      
      -webkit-transform: translateX(4px);
      transform: translateX(4px);
    }
    to {
      opacity: 1;
      -webkit-transform: translateX(-4);
      transform: translateX(-4);
    }
`;

const Container = styled.section`
  background-color: ${BACKGROUND_TWO};
  color: white;
  flex: 1;
  font-size: 35px;
  padding: 3.4rem 0;

  .invalid-cnpj {
    max-width: 1080px;
    width: 100%;
    display: block;
    margin: 0.6rem auto 0;
    padding: 0 2.4rem;
    color: ${BACKGROUND};
    font-size: 1.2rem;
    font-weight: bold;
  }

  @media (max-height: 640px) {
    padding: 3.2rem 0;
  }
`;
Container.displayName = 'ActionsWrapper';

const Wrapper = styled.div`
  line-height: 0;
  margin: 0 auto;
  width: 100%;
  max-width: 1080px;
  position: relative;
  animation: ${fade} 0.25s linear;
  padding: 0 2.4rem;

  svg {
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 2.4rem;
  }
`;

const Input = styled(InputMask).attrs({
  maskChar: null
})`
  background-color: ${BACKGROUND_TWO};
  border: none;
  word-break: break-all;
  color: ${WHITE};
  cursor: auto;
  font-size: 1.6rem;
  height: 2rem;
  outline: none;
  resize: none;
  width: 100%;

  ::placeholder {
    color: ${WHITE};
    opacity: 1;
  }
`;
