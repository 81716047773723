import React, { createContext, useState, useContext } from 'react';

interface DocumentContextData {
  userDocument: string | null;
  setUserDocument(value: string): void;
}

const Document = createContext<DocumentContextData>({} as DocumentContextData);

export const DocumentProvider: React.FC = ({ children }) => {
  const [userDocument, setUserDocument] = useState<string | null>(null);

  return (
    <Document.Provider value={{ userDocument, setUserDocument }}>
      {children}
    </Document.Provider>
  );
};

export function useDocument(): DocumentContextData {
  const context = useContext(Document);

  return context;
}
